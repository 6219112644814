import { AspectRatio, Box, Center, Image, Text, Link } from "@chakra-ui/react"
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/md";
import Carousel from "react-multi-carousel";

import { map } from "lodash";
import { useSchoolGalleryStore } from "../../store/gallery";

export const Gallery = ({ width }) => {

    const { gallery } = useSchoolGalleryStore(s => ({
        gallery: s.gallery
    }))

    const responsive = {
        superLargeDesktop: {
            breakpoint: { max: 4000, min: 3000 },
            items: 4,
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 4
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 3,
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1,
        }
    };

    return (
        <Box w='full' py='40px'>
            {gallery && gallery.length ?
                <Box id='gallery' w={width} mx='auto'>
                    <Box textAlign='center' px='15px' >
                        <Text fontWeight={500} fontSize='30px' mt='20px' mb='10px'>GALLERY</Text>
                        <Box h='20px'></Box>
                    </Box>
                    <Carousel
                        swipeable={false}
                        draggable={false}
                        showDots={false}
                        responsive={responsive}
                        centerMode={false}
                        ssr={true}
                        infinite={true}
                        partialVisbile={false}
                        autoPlay={true}
                        customRightArrow={<CustomRightArrow direction='right' display={'flex'} />}
                        customLeftArrow={<CustomLeftArrow direction='left' display={'flex'} />}
                    >
                        {map(gallery, (album, index) =>
                            <Album album={album} key={album._id} />
                        )}

                    </Carousel>
                </Box>
                :
                null
            }
        </Box>
    )
}

export const CustomRightArrow = ({ onClick, display }) => {
    return (
        <>
            <Center onClick={onClick} pos='absolute' display={display} cursor='pointer' right='-3%' color='white' borderRadius='50%' bg='#bd0745' w='45px' h='45px' mr='40px' ml='32px' zIndex={98}>
                <MdKeyboardArrowRight fontSize='24px' />
            </Center>
        </>
    )
}
export const CustomLeftArrow = ({ onClick, display }) => {
    return (
        <>
            <Center onClick={onClick} pos='absolute' display={display} cursor='pointer' left='-2%' color='white' borderRadius='50%' bg='#bd0745' w='45px' h='45px' mr='40px' ml='32px' zIndex={98}>
                <MdKeyboardArrowLeft fontSize='24px' />
            </Center>
        </>
    )
}

export const Album = ({ key, album, width }) => {
    return (
        <Link _hover={{ textDecoration: 'none' }} role='group' href={'/gallery-detail?id=' + album._id} >
            <Box key={key} mb='10px' px='15px' pos='relative' w={width}>
                <AspectRatio ratio={3 / 2}>
                    <Image borderRadius='4px 4px 0px 0px' src={album.thumbnail || 'assets/noImage.png'} objectFit='cover' crossOrigin='anonymous' />
                </AspectRatio>
                <Center bg='#bd0745' px='20px' py='4px' textAlign='center'>
                    <Text color='white' fontSize='14px' display='inline-block' w='180px' noOfLines={1} overflow='hidden' textOverflow='ellipsis'>{album.title}</Text>
                </Center>
                <Center bg='rgb(32, 44, 69)' px='20px' transform='scaleY(1)' py='10px' borderRadius='0px 0px 4px 4px' cursor='pointer'>
                    <Text color='white' fontSize='14px'>+ View More</Text>
                </Center>
            </Box>
        </Link>
    )
}