import create from 'zustand';
import { STATUS } from '../../constants';
import { apis } from '../../service/apis/apis';

export const useSchoolGalleryStore = create((set, get) => ({
    status: 'idle',
    token: null,
    resetStatus: async () => {
        set({
        })
    },

    getSchoolGalleryAction: async (payload) => {
        set({ getSchoolGalleryStatus: STATUS.FETCHING })
        const response = await apis.getGalleryApi(payload)
        if (response.ok) {
            set({ gallery: response.data, getSchoolGalleryStatus: STATUS.SUCCESS })
        }
        else {
            set({ getSchoolGalleryStatus: STATUS.FAILED })
        }
    }

}))