import { ArrowForwardIcon } from "@chakra-ui/icons";
import { AspectRatio, Box, Button, Center, Flex, Image, Link, Text } from "@chakra-ui/react"
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { useStaffStore } from "../../store/staff";
import { filter, map } from "lodash";
import { useMemo } from "react";

export const Desk = ({ width }) => {

    const { staffData } = useStaffStore(s => ({
        staffData: s.staffData
    }))

    const responsive = {
        superLargeDesktop: {
            breakpoint: { max: 4000, min: 3000 },
            items: 1,
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 1
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 1,
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1,
        }
    };

    const deskData = useMemo(() => filter(staffData, s => s.staffType === 'DESK'), [staffData])
    return (
        <Box w='full' bg='#f8f8f8' mt='40px'>
            {deskData && deskData.length ?
                <Box id='gallery' py='40px' w={width} mx='auto'>
                    <Box textAlign='center' px='15px' >
                        <Text fontWeight={500} fontSize='30px' mt='20px' mb='10px'>FROM THE DESK OF THE</Text>
                        <Box h='20px'></Box>
                    </Box>
                    <Box h='fit-content'>
                        <Carousel
                            swipeable={false}
                            draggable={false}
                            showDots={false}
                            responsive={responsive}
                            infinite={true}
                            partialVisbile={false}
                            autoPlay={true}
                            arrows={false}
                        >
                            {map(deskData, s =>
                                <Box mb='10px' key={s._id} px='15px'>
                                    <Text fontWeight={500} fontSize='22px'>{`${s.designation}'s Message`}</Text>
                                    <Flex flexDir={{ base: 'column-reverse', md: 'row', lg: 'row' }}>
                                        <Box pos='relative' flex={3 / 4} pr='15px'>
                                            <Center><Image src='assets/invertedComma.png' w={{ base: '170px', md: '200px', lg: '220px' }} crossOrigin='anonymous' /></Center>
                                            <Box pos='absolute' left={0} top={0}><Text fontSize='16px'>{s.desc}</Text></Box>
                                        </Box>
                                        <Box px='15px' flex={1 / 4} textAlign='center' >
                                            <AspectRatio ratio={1}>
                                                <Image alt='Desk' objectFit='cover' borderRadius='4px' src={s.avatar || 'assets/blank_profile.webp'} crossOrigin='anonymous' />
                                            </AspectRatio>
                                            <Text fontWeight={400}>{s.name}</Text>
                                            <Text fontWeight={400}>{`(${s.designation})`}</Text>
                                        </Box>
                                    </Flex>
                                </Box>
                            )}
                        </Carousel>
                    </Box>
                    <Link href='/messages' _hover={{ textDecor: 'none' }}>
                        <Center>
                            <Button size='sm' role='group' borderRadius='17px' bg='#bd0745' color='white' _hover={{ bg: '#9c0639' }}>Read More
                                <ArrowForwardIcon
                                    fontSize='18px'
                                    opacity={0}
                                    transition="all 0.2s"
                                    ml="-10px"
                                    _groupHover={{ opacity: 1, marginLeft: "5px" }}
                                />
                            </Button>
                        </Center>
                    </Link>
                </Box>
                :
                null
            }
        </Box>
    )
}
