import { concat, map, filter } from 'lodash';
import create from 'zustand';
import { STATUS } from '../../constants';
import { ErrorAlert, SuccessAlert } from '../../utils/Helper';
import { apis } from '../../service/apis/apis';

export const useFeedbackStore = create((set, get) => ({
    status: 'idle',
    token: null,
    getFeedbackStatus: STATUS.NOT_STARTED,
    resetStatus: async () => {
        set({
            addFeedbackStatus: STATUS.NOT_STARTED,
        })
    },

    addFeedbackAction: async (payload) => {
        set({ addFeedbackStatus: STATUS.FETCHING })
        const response = await apis.addFeedbackApi(payload)
        let prevFeedback = get().feedback || []

        if (response.ok) {
            SuccessAlert(response.msg || 'Added')
            set({ feedback: [...prevFeedback, response.data], addFeedbackStatus: STATUS.SUCCESS })
        }
        else {
            ErrorAlert()
            set({ addFeedbackStatus: STATUS.FAILED })
        }
    },
    
}))
