import { AspectRatio, Box, Text } from "@chakra-ui/react"

export const CampusTour = ({ width, campusTour }) => {
    if(!campusTour) return;
    return (
        <Box w='full' display='flex' flexDir='column' alignItems='center' mt='40px' pt='40px'>
            <Box w={width} align='center' display='flex' flexDir={{ base: 'column', md: 'row', lg: 'row' }}>
                <Box flex={1} p='24px'>
                    <AspectRatio ratio={16 / 9}>
                        <iframe src={campusTour.value} style={{width:"100%", height:"100%"}} title='School' allowFullScreen />
                    </AspectRatio>
                </Box>
                <Box flex={1} p='24px' textAlign='center'>
                    <Text fontSize='59px' fontWeight={700}>Campus Tour</Text>
                    <Text fontSize='22px' fontWeight={200} textShadow='52px 33px 82px rgba(0,0,0,0.3)'>VIRTUAL CAMPUS TOURS ENABLE PROSPECTIVE STUDENTS TO EXPLORE A SCHOOL CAMPUS.</Text>
                </Box>
            </Box>
        </Box>
    )
} 