import { BsWhatsapp, BsPinterest } from "react-icons/bs";
import { FaFacebookF, FaLinkedinIn, FaGooglePlusG } from "react-icons/fa"
import { AiOutlineTwitter, AiOutlineInstagram } from "react-icons/ai"
import { ImYoutube2 } from "react-icons/im"

export const SOCIALMEDIAICONS = [
    { icon: <BsWhatsapp fontSize='14px' />, title:'whatsApp' },
    { icon: <FaFacebookF fontSize='13px' />, title:'facebook' },
    { icon: <AiOutlineTwitter fontSize='17px' />, title:'twitter' },
    { icon: <FaGooglePlusG fontSize='19px' />, title:'google' },
    { icon: <ImYoutube2 fontSize='20p' />, title:'youtube' },
    { icon: <FaLinkedinIn fontSize='15px' />, title:'linkedIn' },
    { icon: <AiOutlineInstagram fontSize='16px' />, title:'instagram' },
    { icon: <BsPinterest fontSize='13px' />, title:'pinterest' },
]

export const NAVLINKS = [
    {
        title: 'Home',
        route: '/',
    },
    {
        title: 'About Us',
        children: [
            {
                title: 'About Us',
                route: '/about-us'
            },
            {
                title: 'Our Vision & Mission',
                route: '/vision-and-mission'
            },
            {
                title: 'Our Foundation',
                route: '/foundation'
            }
        ]
    },
    {
        title: 'Facilities',
        children: []
    },
    {
        title: 'Gallery',
        route: '/gallery'
    },
    {
        title: 'Events',
        route: '/events'
    },
    {
        title: 'News',
        route: '/news'
    },
    {
        title: 'Messages',
        route: '/messages'
    },
    {
        title: 'Contact Us',
        route: '/contact'
    },
]

export const FACILITIES = [
    { title: 'Hostel', image: 'assets/scholarship-icon.png', id:'HOSTEL', route:'/hostel-facility' },
    { title: 'Books & Library', image: 'assets/book-icon.png', id:'LIBRARY', route: '/library' },
    { title: 'Club', image: 'assets/teacher-icon.png', id:'CLUB', route:'/clubs' },
    { title: 'Lab', image: 'assets/teacher-icon.png', id:'LAB', route:'/labs' },
    { title: 'Sports', image: 'assets/teacher-icon.png', id:'SPORTS', route:'/sports' },
]

export const STATUS = {
    NOT_STARTED: 'NOT_STARTED',
    FETCHING: 'FETCHING',
    SUCCESS: 'SUCCESS',
    FAILED: 'FAILED',
    INVALID: 'INVALID',
};
