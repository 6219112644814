import { Box, Flex, Text, HStack, Button, Image, Link, Center, Menu, MenuButton, IconButton, MenuList, MenuItem, useBreakpointValue, useDisclosure, Popover, PopoverTrigger, PopoverContent, MenuGroup } from "@chakra-ui/react";
import { CgMail } from 'react-icons/cg'
import { FaPhoneAlt } from 'react-icons/fa'
import { HiOutlineMenu } from 'react-icons/hi'
import { filter, find, findIndex, map, orderBy, some } from "lodash"
import { NAVLINKS, SOCIALMEDIAICONS } from "../../constants";
import { useEffect, useState } from "react";
import { useLocation } from "react-router";
import { Login } from "../../components/login";
import { MdArrowDropDown } from "react-icons/md";
import { useSchoolInformationStore } from "../../store/schoolInformation";
import { useSchoolNewsStore } from "../../store/news";
import dayjs from "dayjs";
import { SchoolId } from "../../config";
import { useSchoolGalleryStore } from "../../store/gallery";
import { useSchoolFacilityStore } from "../../store/facility";

export const Header = () => {
    const [scrollPosition, setScrollPosition] = useState(0);
    const [facilitiesChildren, setFacilitiesChildren] = useState([]);
    const { isOpen, onClose, onOpen } = useDisclosure()
    const { schoolData } = useSchoolInformationStore(s => ({ schoolData: s.schoolData }))

    const { getSchoolNewsAction, schoolNewsData } = useSchoolNewsStore(s => ({
        getSchoolNewsAction: s.getSchoolNewsAction,
        schoolNewsData: s.schoolNewsData,
    }))

    const { getSchoolGalleryAction, gallery } = useSchoolGalleryStore(s => ({
        getSchoolGalleryAction: s.getSchoolGalleryAction,
        gallery: s.gallery
    }))

    const { getSchoolFacilityAction, facilities } = useSchoolFacilityStore(s => ({
        getSchoolFacilityAction: s.getSchoolFacilityAction,
        facilities: s.facilities
    }))

    useEffect(() => {
        getSchoolGalleryAction({ schoolId: SchoolId })
        getSchoolFacilityAction({ schoolId: SchoolId })
        getSchoolNewsAction({ schoolId: SchoolId })
    }, [getSchoolFacilityAction, getSchoolGalleryAction, getSchoolNewsAction])

    useEffect(() => {
        const handleScroll = () => {
            setScrollPosition(window.pageYOffset);
        };
        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    const width = useBreakpointValue({ base: '395px', md: '750px', lg: '970px', xl: '1270px' });

    const location = useLocation()
    const path = `${location.pathname}${location.hash}`;

    useEffect(() => {
        const FacilityChildren = [
            {
                title: 'Sports',
                route: '/sports',
                key: 'SPORTS'
            },
            {
                title: 'Clubs',
                route: '/clubs',
                key: 'CLUB'
            },
            {
                title: 'Hostel Facility',
                route: '/hostel-facility',
                key: 'HOSTEL'
            },
            {
                title: 'Library',
                route: '/library',
                key: 'LIBRARY'
            },
            {
                title: 'Labs',
                route: '/labs',
                key: 'LAB'
            },
        ]
        setFacilitiesChildren(filter(FacilityChildren, fac => some
            (facilities, f => f.title === fac.key)))
    }, [facilities])

    
    const facilitiesObjectIndex = findIndex(NAVLINKS, item => item.title === 'Facilities');
    NAVLINKS[facilitiesObjectIndex].children = facilitiesChildren;

    return (
        <Box>
            <Box position='relative' bg='#202c45' w='100%' h='33px' display='flex' justifyContent='center'>
                <Flex w={width} h='33px' px='15px' ml={0} left='16px' alignItem='center'>
                    <Flex justifyContent='flex-start' bg='#bd0745' w={40} px='8px' py='7px' mr='10px' color='white' zIndex={1}>
                        Latest News
                    </Flex>
                    <marquee>
                        {orderBy(schoolNewsData, 'updatedAt', 'desc').map(news =>
                            <Box pl='10px' mx='120px' key={news._id} display='inline-block' h='80px' p='6px 10px 0px 60px' overflow='hidden'>
                                <Text pos='fixed' w='100%' color='#FFEB3B' fontWeight={500}> {dayjs(news.updatedAt).format('DD MMM YYYY')} <span>{news.title}</span></Text>
                            </Box>
                        )}
                    </marquee>
                </Flex>
            </Box>
            <Flex justifyContent='center' py='3.5px' bg='#bd0745'>
                <Flex w={width} flexDir={{ base: 'column', md: 'row', lg: 'row' }} fontSize='14px' color='white' px='15px' justifyContent='space-between' alignItems='center'>
                    <HStack cursor='pointer' alignItems='center'><CgMail fontSize='16px' /><a href='/' style={{ hover: { textDecoration: 'underline' } }}>{schoolData?.email}</a></HStack>
                    <HStack alignItems='center' spacing={2}>
                        <Text fontSize='14px'>Follow Us</Text>
                        <Flex>{map(SOCIALMEDIAICONS, socialMedia => {
                            const isCheck = find(schoolData?.links, link => link.title === socialMedia.title)
                            return (
                                isCheck ?
                                    <a href={isCheck.value} target='_blank' rel="noreferrer">
                                        <Button p='0px' size='sm' bg='#bd0745' _hover={{ bg: '#333', border: '1px solid', borderColor: 'white', transition: 'all 1s ease 0s' }} borderRadius={0}>
                                            {socialMedia.icon}
                                        </Button>
                                    </a>
                                    :
                                    null
                            )
                        }
                        )}
                        </Flex>
                    </HStack>
                </Flex>
            </Flex>
            <Box zIndex={100} w='full' top={scrollPosition ? '0px' : 'none'} pos={scrollPosition ? 'fixed' : 'relative'} bg='white' transition='all 1s'>
                <Flex mb='10px' pt='10px' justifyContent='center' >
                    <Flex w={width} flexDir={{ base: 'column', md: 'row', lg: 'row' }} justifyContent='space-between' alignItems='center'>
                        <Flex w='100%' justifyContent='space-between' px='15px' align='center'>
                            <HStack>
                                <Image src={schoolData?.logo} transition='all 1s' w={[{ base: '239px', md: '363px', lg: scrollPosition ? '263px' : '363px' }]} h={{ lg: scrollPosition ? '45px' : '76px', base: scrollPosition ? '45px' : '50px' }} mr={{ base: '5px' }} alt='image' crossOrigin='anonymous' />
                                <Text color='#6b0f11' fontWeight={700} textShadow='2px 1px 1px #e8c480' fontSize={{ lg: scrollPosition ? '22px' : '26px' }}>{schoolData?.name}</Text>
                            </HStack>
                            <Flex justify='center' align='center' display={{ base: 'flex', md: 'none', lg: 'none' }}>
                                <Menu>
                                    <MenuButton
                                        as={IconButton}
                                        bg='#bd0745'
                                        border='1px solid black'
                                        _hover={{ bg: '#bd0745' }}
                                        color='white'
                                        variant='flushed'
                                        icon={<HiOutlineMenu fontSize='24px' />}
                                    />
                                    <MenuList>
                                        <Box>
                                            {map(NAVLINKS, (n, index) => {
                                                return (
                                                    (n.children && n.children.length) ?
                                                        <MenuGroup title={n.title}>
                                                            {map(n.children, (child, index) =>
                                                                <MenuItem key={index}>
                                                                    <Link href={child.route} _hover={{ textDecor: 'none' }}>
                                                                        <Box px={4} transition='all 1s' py={1} _hover={{ bg: '#bd0745', color: 'white' }} >
                                                                            <Text fontWeight={400} >
                                                                                {child.title}
                                                                            </Text>
                                                                        </Box>
                                                                    </Link>
                                                                </MenuItem>
                                                            )}
                                                        </MenuGroup>
                                                        :
                                                        <MenuItem>
                                                            <Link href={n.route} _hover={{ textDecor: 'none' }} key={index}>
                                                                <Box px={4} transition='all 1s' py={1} _hover={{ bg: '#bd0745', color: 'white' }} >
                                                                    <Text fontWeight={400} >
                                                                        {n.title}
                                                                    </Text>
                                                                </Box>
                                                            </Link>
                                                        </MenuItem>
                                                )
                                            }
                                            )}
                                        </Box>
                                    </MenuList>
                                </Menu>
                            </Flex>
                        </Flex>
                        <HStack alignItems='center' display={{ base: scrollPosition ? 'none' : 'flex', md: 'flex', lg: 'flex' }} transition='all 1s' mt={[{ base: '4px', lg: scrollPosition ? '4px' : '25px' }]}>
                            {schoolData ?
                                <>
                                    <Center mb='7px' mr='11px' mt='4px' border='1px solid #bd0745' w='34px' h='34px' borderRadius='100%'><FaPhoneAlt color="#bd0745" /></Center>
                                    <Box>
                                        <Text color='#848181'>Call Us</Text>
                                        <Text color='#bd0745'>{schoolData.contact}</Text>
                                    </Box>
                                </>
                                :
                                null
                            }
                        </HStack>
                    </Flex>
                </Flex>
                <Flex display={{ base: 'none', md: 'flex', lg: 'flex' }} flexWrap='wrap' boxShadow='0 6px 12px rgba(0,0,0,.175)' justifyContent='center' borderTop='1px solid #e8e8e8' zIndex={99}>
                    <Flex w={width} wrap='wrap'>
                        {map(NAVLINKS, (nav, index) => {
                            const isCheckPath = (path === nav.route) ? true : false
                            return (
                                <Link href={nav.route} key={index} _hover={{ textDecor: 'none' }}>
                                    <Box transition='all 1s' bg={isCheckPath ? '#f6f6f6' : 'white'} color={isCheckPath ? '#bd0745' : 'black'} borderTop={isCheckPath ? '1px solid #bd0745' : '1px solid #e8e8e8'} _hover={{ color: '#bd0745', bg: '#f6f6f6', borderTop: '1px solid #bd0745' }} px='15px' py={scrollPosition > 0 ? '10px' : '23px'} mt='-1px' fontSize='14px'>
                                        {
                                            (nav.children && nav.children.length) ?
                                                <Popover trigger="hover" openDelay={100} placement="bottom" border='1px solid rgba(0,0,0,.15)' boxShadow='0 6px 12px rgba(0, 0, 0, .175)' transition='all 0.2s'>
                                                    <PopoverTrigger>
                                                        <HStack>
                                                            <Text>{nav.title}</Text>
                                                            <MdArrowDropDown />
                                                        </HStack>
                                                    </PopoverTrigger>
                                                    <PopoverContent w='fit-content' borderRadius={0} borderTop='1px solid #bd0745' color='black' >
                                                        {map(nav.children, (n, index) =>
                                                            <Link href={n.route} _hover={{ textDecor: 'none' }} key={index}>
                                                                <Box px={4} transition='all 1s' py={1} _hover={{ bg: '#bd0745', color: 'white' }} >
                                                                    <Text fontWeight={400} >
                                                                        {n.title}
                                                                    </Text>
                                                                </Box>
                                                            </Link>
                                                        )}
                                                    </PopoverContent>
                                                </Popover>
                                                :
                                                <Text >{nav.title}</Text>
                                        }
                                    </Box>
                                </Link>
                            )
                        }
                        )}
                    </Flex>
                </Flex>
            </Box>
            {isOpen && <Login isOpen={isOpen} onClose={onClose} />}
        </Box >
    )
}