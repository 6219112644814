import { Box, Text, useBreakpointValue } from "@chakra-ui/react"
import MainLayout from "../../app/layouts/MainLayout"
import { useSchoolInformationStore } from "../../store/schoolInformation";

export const VisionAndMission = () => {
    const width = useBreakpointValue({ sm: '395px', md: '720px', lg: '970px', xl: '1270px' });
    const {schoolData} = useSchoolInformationStore(s => ({schoolData: s.schoolData}))
    return (
        <MainLayout>
            <Box py='40px' w='full'>
                <Box px='15px' w={width} mx='auto'>
                    <Box>
                        <Text fontWeight={500} fontSize='30px' mt='20px' mb='10px'>Vision</Text>
                        <Text fontSize='16px' mb='10px'>{schoolData?.vision}</Text>
                    </Box>
                    <Box>
                        <Text fontWeight={500} fontSize='30px' mt='20px' mb='10px'>Mission</Text>
                        <Text fontSize='16px' mb='10px'>{schoolData?.mission}</Text>
                    </Box>
                </Box>
            </Box>
        </MainLayout>
    )
}