import './App.css'
// import Head from 'next/head';
import { ChakraProvider, createStandaloneToast } from "@chakra-ui/react"
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Homepage } from './components/homePage/index';
import { About } from './components/aboutUs/About';
import { VisionAndMission } from './components/aboutUs/VisionAndMission';
import { Foundation } from './components/aboutUs/Foundation';
import { Message } from './components/message';
import { ComplainForm } from './components/complain';
import { Toppers } from './components/toppers';
import { Contact } from './components/contact';
import { FeedbackForm } from './components/feedBack';
import { Sports } from './components/sports';
import { HostelFacility } from './components/hostelFacility';
import { Clubs } from './components/clubs';
import { Labs } from './components/labs';
import { Library } from './components/library';
import { Gallery } from './components/gallery';
import { GalleryDetail } from './components/galleryDetail';
import { News } from './components/news';
import { customTheme } from './theme';
import { Events } from './components/events';
// import Head from 'next/head';
// import NProgress from 'nprogress';

// Router.onRouteChangeStart = (r) => { if(isNotSamePathname(r)) NProgress.start() }
// Router.onRouteChangeComplete = (r) => { if(isNotSamePathname(r)) NProgress.done(); }
// Router.onRouteChangeError = (r) => { if(isNotSamePathname(r)) NProgress.done(); }

// const isNotSamePathname = (route) => {
//   const changeRoute = route.split('?')[0];
//   return window.location.pathname !== changeRoute
// } 

const { ToastContainer, toast } = createStandaloneToast({
  theme: customTheme,
});

function App({ Component, pageProps }) {
  // const getLayout = Component.getLayout || ((page) => page);
  return (
    <>
    {/* <Head>
        <title>School Website</title>
        <meta
          name="viewport"
          content="minimum-scale=1, initial-scale=1, width=device-width"
        />
      </Head> */}
    <Router>
      <ChakraProvider theme={customTheme}>
        <Container />
      </ChakraProvider>
    </Router>
    </>
  );
}

const Container = () => {
  return (
    <Routes>
      <Route exact path="/" element={<Homepage />} />
      <Route path="about-us" element={<About />} />
      <Route path="/vision-and-mission" element={<VisionAndMission />} />
      <Route path="/foundation" element={<Foundation />} />
      <Route path="/messages" element={<Message />} />
      <Route path="/events" element={<Events />} />
      <Route path="/news" element={<News />} />
      <Route path="/contact" element={<Contact />} />
      <Route path="/complain" element={<ComplainForm />} />
      <Route path="/feedback" element={<FeedbackForm />} />
      <Route path="/toppers" element={<Toppers />} />
      <Route path="/sports" element={<Sports />} />
      <Route path="/hostel-facility" element={<HostelFacility />} />
      <Route path="/clubs" element={<Clubs />} />
      <Route path="/labs" element={<Labs />} />
      <Route path="/library" element={<Library />} />
      <Route path="/gallery" element={<Gallery />} />
      <Route path="/gallery-detail" element={<GalleryDetail />} />
    </Routes>
  )
}

export default App;
