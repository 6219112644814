import { Box, Center, Flex, HStack, Image, Text, Link } from "@chakra-ui/react"
import { find, map } from "lodash"
import { CgMail } from "react-icons/cg"
import { FaPhoneAlt, FaRegEdit } from "react-icons/fa"
import { IoMdArrowDropright } from "react-icons/io"
import { MdLocationOn, MdOutlineFeedback } from "react-icons/md"
import { SOCIALMEDIAICONS } from "../../constants"
import { useSchoolInformationStore } from "../../store/schoolInformation"

export const Footer = () => {
    const Links = [
        { title: 'Home', route: '/' },
        { title: 'Message', route: '/messages' },
        { title: 'Events', route: '/events' },
        { title: 'Contact Us', route: '/contact' },
        { title: 'About Us', route: '/about-us' },
        { title: 'Gallery', route: '/gallery' },
        { title: 'News', route: '/news' },
    ]
    const { schoolData } = useSchoolInformationStore(s => ({ schoolData: s.schoolData }))

    return (
        <Box pos='relative' w='full' h={{ base: '878px', md: '694px', lg: '410px', xl: '493px' }}>
            <Image src='assets/footer.jpg' h='100%' w='100%' objectFit={'cover'} />
            <Box pos='absolute' bg='rgba(0, 0, 0, 0.85)' h='100%' w='100%' top={0} left={0}>
                <Box w={{ base: '395px', md: '750px', lg: '970px', xl: '1270px' }} m='auto' px='15px' py='40px'>
                    <Flex flexDir={{ base: 'column', md: 'row', lg: 'row' }}>
                        <FooterContent title='Link'>
                            <Flex mt='25px' flexWrap='wrap'>
                                {map(Links, (link, index) =>
                                    <Link _hover={{ textDecor: 'none' }} href={link.route} w='50%' key={index}>
                                        <HStack alignItems='center' pr='15px' py='6px' cursor='pointer'>
                                            <IoMdArrowDropright color="#8c8587" />
                                            <Text fontSize='14px' color='white' _hover={{ color: '#8c8587' }} transition='all 0.6s'>
                                                {link.title}
                                            </Text>
                                        </HStack>
                                    </Link>
                                )}
                            </Flex>
                        </FooterContent>
                        {
                            (schoolData && schoolData.links?.length) ?
                                <FooterContent title='Follow Us'>
                                    <HStack mt='25px' spacing={1}>
                                        {map(SOCIALMEDIAICONS, (socialMedia, index) => {
                                            const isCheck = find(schoolData?.links, link => link.title === socialMedia.title)
                                            return (
                                                isCheck ?
                                                    <a href={isCheck.value} key={index} target="_blank" rel="noreferrer">
                                                        <Center border='1px solid #8c8587' _hover={{ bg: '#bd0745' }} transition='background .6s ease-in-out' p={0} color='white' w='31px' h='31px'>
                                                            {socialMedia.icon}
                                                        </Center>
                                                    </a>
                                                    :
                                                    null

                                            )
                                        }
                                        )}
                                    </HStack>
                                </FooterContent>
                                :
                                null
                        }
                        <FooterContent title='Feedback'>
                            <Box>
                                <Link href='/complain' _hover={{ textDecor: 'none' }}>
                                    <HStack mt='25px' cursor='pointer'><FaRegEdit fontSize={40} color='white' />
                                        <Text fontSize='24px' fontWeight={500} _hover={{ color: '#8c8587' }} transition='all 0.6s' color='white'>Complain</Text>
                                    </HStack>
                                </Link>
                                <Link href='/feedback' _hover={{ textDecor: 'none' }}>
                                    <HStack mt='25px' cursor='pointer'><MdOutlineFeedback fontSize={40} color='white' />
                                        <Text fontSize='24px' fontWeight={500} _hover={{ color: '#8c8587' }} transition='all 0.6s' color='white'>Feedback</Text>
                                    </HStack>
                                </Link>
                            </Box>
                        </FooterContent>
                    </Flex>
                    <Box borderTop='1px solid #8c8587' my='20px'>
                        <Contacts schoolData={schoolData} />
                    </Box>
                </Box>
            </Box>
            <Center h={['50px']} w='full' bg='#8c0533' py='15px' color='white'>
                <Box w={{ base: '325px', md: '750px', lg: '970px', xl: '1300px' }} textAlign='center'>{`© ${schoolData?.name} 2023 All rights reserved`}</Box>
            </Center>
        </Box >
    )
}

const UnderLine = () => {
    return (
        <Box w='50px' h='2px' mb='20px' bg='#8c8587' pos='absolute'>
        </Box>
    )
}

const FooterContent = ({ children, title }) => {
    return (
        <Box flex={1} px='15px' >
            <Text color='white' mt='25px' fontWeight={500} pb='10px' fontSize='24px'>{title}</Text>
            <UnderLine />
            {children}
        </Box>
    )
}

const Contacts = ({ schoolData }) => {

    const Contact = [
        { icon: <FaPhoneAlt fontSize='14px' />, name: 'Contact', des: schoolData?.contact },
        { icon: <CgMail fontSize='18px' />, name: 'Email', des: schoolData?.email },
        { icon: <MdLocationOn fontSize='16px' />, name: 'Address', des: schoolData?.address?.streetAddress },
    ]
    return (
        <Flex flexDir={{ base: 'column', md: 'column', lg: 'row' }} >
            {map(Contact, (detail, index) =>
                <Box key={index} px='15px' mt='20px' flex={1} cursor='pointer'>
                    <Flex>
                        <Flex w='50px' h='50px' alignItems='center' color='#8c8587'>{detail.icon}</Flex>
                        <Box fontSize='18px' color='white' borderLeft='1px solid #8c8587' pl='15px'>
                            <Text _hover={{ color: '#f983ab' }} transition='all 0.6s'>{detail.name}</Text>
                            <Text _hover={{ color: '#f983ab' }} transition='all 0.6s' mb='10px'>{detail.des}</Text>
                        </Box>
                    </Flex>
                </Box>
            )}
        </Flex>
    )
}