import { Box, Center, Flex, Text, useBreakpointValue } from "@chakra-ui/react"
import MainLayout from "../../app/layouts/MainLayout"
import { ImLocation2 } from "react-icons/im"
import { join, map } from "lodash";
import { BsTelephoneFill } from "react-icons/bs";
import { AiOutlineClockCircle } from "react-icons/ai";
import { useSchoolInformationStore } from "../../store/schoolInformation";

export const Contact = () => {
    const width = useBreakpointValue({ sm: '395px', md: '720px', lg: '970px', xl: '1270px' });
    const { schoolData } = useSchoolInformationStore(s => ({ schoolData: s.schoolData }))

    const { address, email, contact, schoolStartTime, schoolEndTime } = schoolData || {}
    const Contact = [
        { icon: <ImLocation2 fontSize='40px' />, title: 'LOCATION', desc: join([address?.streetAddress, address?.city, address?.state], ", ") },
        { icon: <BsTelephoneFill fontSize='40px' />, title: 'CALL', desc: `Email: ${email}, Mobile: +91${contact}` },
        { icon: <AiOutlineClockCircle fontSize='40px' />, title: 'WORKING HOURS', desc: `${schoolStartTime} am to ${schoolEndTime} pm \n Sunday: Holiday` },
    ]

    return (
        <MainLayout>
            <Box w='full' my='40px' mx='auto' display='flex' flexDir='column' alignItems='center'>
                <Flex w={width} justify='center' flexDir={{base:'column', md:'row' ,lg:'row'}}>
                    {map(Contact, (c, index) =>
                        <Box key={index} w='33%' px='15px' display='flex' flexDir='column' alignItems='center' justify='center'>
                            <Box textAlign='center' bg='#f1f1f1' w='100%' my='30px' p='30px' border='1px solid #ececec' transition='all .3s ease-in'>
                                <Center mb='20px'>{c.icon}</Center>
                                <Text fontSize='20px' fontWeight={500} mb='10px'>{c.title}</Text>
                                <Text fontSize='14px'>{c.desc}</Text>
                            </Box>
                        </Box>
                    )}

                </Flex>
            </Box>
        </MainLayout>
    )
}