
import { AspectRatio, Box, Center, Flex, Image, Text, useBreakpointValue } from "@chakra-ui/react"
import MainLayout from "../../app/layouts/MainLayout"
import { map } from "lodash";
import { FACILITIES } from "../../constants";
import { useSchoolInformationStore } from "../../store/schoolInformation";

export const About = () => {
    const width = useBreakpointValue({ sm: '395px', md: '720px', lg: '970px', xl: '1270px' });
    const { schoolData } = useSchoolInformationStore(s => ({schoolData: s.schoolData}))
    const { about, aboutImage } = schoolData || {}
 
    return (
        <MainLayout>
            <Box w='full'>
                <Box w={width} px='15px' mx='auto' py='60px'>
                    <Text fontWeight={500} fontSize='30px' mt='20px' mb='10px'>About</Text>
                    <Flex mt='30px' flexDir={{ base: 'column', md: 'row', lg: 'row' }}>
                        <Box flex={1 / 2} pr='15px' mt={{ base: '30px', md: '0px', lg: '0px' }}>
                            <Text fontSize='22px' textTransform='uppercase' fontWeight={500} mb='10px'>WELCOME TO {schoolData?.name}</Text>
                            <Text py='10px' fontSize='16px'>{about}</Text>
                        </Box>
                        <AspectRatio px='15px' flex={1 / 2}>
                            <Image src={aboutImage || 'assets/noImage.png'} alt="about" borderRadius='5px' w='100%' h='100%' objectFit='cover' crossOrigin="anonymous" />
                        </AspectRatio>
                    </Flex>
                </Box>
            </Box>
            {/* <Box bg='#f8f8f8' py='60px' mb='60px' w='full'>
                <Box w={width} px='15px' mx='auto' textAlign='center'>
                    <Center>
                        <Box w={{ lg: '816px' }}>
                            <Text fontWeight={500} fontSize='30px' mt='20px' mb='10px'>Why Choose Our Institution?</Text>
                            <Text fontSize='14px' mb='10px'>Tmply dummy text of the printing and typesetting industry. Lorem Ipsum has been theindustry's standard dummy text ever since the 1500s, when an unknown printer took..</Text>
                        </Box>
                    </Center>
                    <Flex flexDir={{ base: 'column', md: 'row', lg: 'row' }} justify='center'>
                        {map(FACILITIES, f =>
                            <Box key={f.title} transition='all 0.5s ease-out' borderRight='1px solid rgba(255, 255, 255, 0.21)' p='30px 30px 20px 40px' flex='1/3'>
                                <Center><Image src={f.image} alt='image' w='60px' h='60px' /></Center>
                                <Box pl='10px'>
                                    <Text fontSize='24px' fontWeight={500}>{f.title}</Text>
                                    <Text fontSize='16px' fontWeight={400} w='80%'>{f.dec}</Text>
                                </Box>
                            </Box>
                        )}
                    </Flex>
                </Box>
            </Box> */}
        </MainLayout>
    )
}