import { Box, Image, Text } from "@chakra-ui/react"
import { filter, map } from "lodash"
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { CustomLeftArrow, CustomRightArrow } from "./Gallery";
import { useStaffStore } from "../../store/staff";
import { useEffect, useMemo } from "react";
import { SchoolId } from "../../config";

export const ExperiencedStaff = ({ width }) => {
    const { getStaffAction, staffData } = useStaffStore(s => ({
        getStaffAction: s.getStaffAction,
        staffData: s.staffData
    }))

    useEffect(() => {
        if (!staffData && !staffData?.length) {
            getStaffAction({ schoolId: SchoolId })
        }
    }, [getStaffAction, staffData])

    const responsive = {
        superLargeDesktop: {
            breakpoint: { max: 4000, min: 3000 },
            items: 4,
        },
        desktop: {
            breakpoint: { max: 3000, min: 768 },
            items: 4,
        },
        tablet: {
            breakpoint: { max: 768, min: 464 },
            items: 3,
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1,
        }
    };

    const staff = useMemo(() => filter(staffData, s => s.staffType !== 'DESK'), [staffData])

    return (
        <Box w='full'>
            {staff && staff.length ?
                <Box textAlign='center' w={width} px='15px' mx='auto' py='40px'>
                    <Text fontWeight={500} fontSize='30px' mt='20px' mb='10px'>OUR EXPERIENCED STAFFS</Text>
                    <Text fontSize='14px' mb='10px'>Considering desire as primary motivation for the generation of narratives is a useful concept.</Text>
                    <Box h='20px'></Box>
                    <Carousel
                        swipeable={false}
                        draggable={false}
                        responsive={responsive}
                        centerMode={false}
                        infinite={true}
                        autoPlay={true}
                        partialVisbile={false}
                        customRightArrow={<CustomRightArrow direction='right' display={{ base: 'flex', md: 'flex', lg: 'none' }} />}
                        customLeftArrow={<CustomLeftArrow direction='left' display={{ base: 'flex', md: 'flex', lg: 'none' }} />}
                    >
                        {map(staff, (s, index) =>
                            <Box key={index} px='15px' role='group'>
                                <Box borderRadius='50%' pos='relative' transition="all 500ms ease" overflow='hidden' w='295px' h='295px'>
                                    <Image src={s.avatar || 'assets/blank_profile.webp'} w='100%' h='100%' objectFit='cover' alt='Staff' borderRadius='50%' _groupHover={{ transform: 'scale(1.3)' }} transition='all 500ms ease' crossOrigin='anonymous' />
                                    <Box w='100%' h='100%' left={0} top={0} pos='absolute' borderRadius='100%' transform='scale(0.0)' _groupHover={{ transform: 'scale(1.1)', bg: 'rgba(0, 0, 0, 0.5)' }} zIndex={1} transition='all 500ms ease'></Box>
                                </Box>
                                <Text mt='20px' mb='5px' fontWeight={700} fontSize='20px'>{s.name}</Text>
                                <Text fontWeight={400}>{s.designation}</Text>
                            </Box>
                        )}
                    </Carousel>
                </Box>
                :
                null
            }
        </Box>
    )
}