import { AspectRatio, Box, Flex, Image, Text, useBreakpointValue } from "@chakra-ui/react"
import MainLayout from "../../app/layouts/MainLayout"
import { useStaffStore } from "../../store/staff";
import { filter, map } from "lodash";
import { useEffect, useMemo } from "react";
import { SchoolId } from "../../config";
import { EmptyBox } from "../../common/EmptyBox";

export const Message = () => {
    const width = useBreakpointValue({ sm: '395px', md: '720px', lg: '970px', xl: '1270px' });

    const { getStaffAction, staffData } = useStaffStore(s => ({
        getStaffAction: s.getStaffAction,
        staffData: s.staffData
    }))

    useEffect(() => {
        if (!staffData && !staffData?.length) {
            getStaffAction({ schoolId: SchoolId })
        }
    }, [getStaffAction, staffData])

    const deskData = useMemo(() => filter(staffData, s => s.staffType === 'DESK'), [staffData])

    return (
        <MainLayout>
            <Box mb='10px' px='15px' py='40px' w='full'>
                {deskData && deskData.length ?
                    map(deskData, s =>
                        <Box m='10px' key={s._id} px='15px' w={width} mx='auto'>
                            <Text fontWeight={500} fontSize='22px'>{`${s.designation}'s Message`}</Text>
                            <Flex >
                                <Box flex={1}>
                                    <Text fontSize='16px'>{s.desc}</Text>
                                </Box>
                                <Box px='15px' flex={1 / 2} textAlign='center'>
                                    <AspectRatio ratio={1} maxW='400px'>
                                        <Image alt='Desk' objectFit='cover' src={s.avatar || 'assets/blank_profile.webp'} crossOrigin='anonymous' />
                                    </AspectRatio>
                                    <Text fontWeight={400}>{s.name}</Text>
                                    <Text fontWeight={400}>{`(${s.designation})`}</Text>
                                </Box>
                            </Flex>
                        </Box>
                    )
                    :
                    <EmptyBox />
                }
            </Box>
        </MainLayout>
    )
}