import { Box, Text, useBreakpointValue } from "@chakra-ui/react"
import MainLayout from "../../app/layouts/MainLayout"
import { find } from "lodash";
import { useSchoolFacilityStore } from "../../store/facility";
import { useMemo } from "react";

export const Sports = () => {
    const width = useBreakpointValue({ sm: '395px', md: '720px', lg: '970px', xl: '1270px' });
   const { facilities } = useSchoolFacilityStore(s => ({ facilities: s.facilities }))
   const sports = useMemo(() => find(facilities, f => f.title === 'SPORTS'), [facilities])

    return (
        <MainLayout>
            <Box py='40px' w='full'>
                <Box px='15px' w={width} mx='auto'>
                    <Box>
                        <Text fontWeight={500} fontSize='30px' mt='20px' mb='10px'>Sports</Text>
                        <Text fontSize='16px' mb='10px'>{sports?.description}</Text>
                    </Box>
                </Box>
            </Box>
        </MainLayout>
    )
}