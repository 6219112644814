import { AspectRatio, Box, Image, useBreakpointValue } from "@chakra-ui/react";
import { AboutUs, Facilities } from "./AboutUs";
import { Achivement } from "./Achivements";
import { ExperiencedStaff } from "./ExperiencedStaff";
import MainLayout from "../../app/layouts/MainLayout";
import { Gallery } from "./Gallery";
import { Desk } from "./Desk";
import { CampusTour } from "./CampusTour";
import { Toppers } from "./Topper";
import { useSchoolInformationStore } from "../../store/schoolInformation";
import { find } from "lodash";
import { useCrousalStore } from "../../store/crousal";
import { useEffect } from "react";
import { SchoolId } from "../../config";
import { useSchoolFacilityStore } from "../../store/facility";

export const Homepage = () => {
    const { schoolData } = useSchoolInformationStore(s => ({ schoolData: s.schoolData }))
    const campusTour = find(schoolData?.links, link => link.title === 'campusTour')
    const { facilities } = useSchoolFacilityStore(s => ({ facilities: s.facilities }))

    const { crousal, getCrousalAction } = useCrousalStore(s => ({
        crousal: s.crousal,
        getCrousalAction: s.getCrousalAction
    }))

    useEffect(() => {
        if (!crousal) {
            getCrousalAction({ schoolId: SchoolId })
        }
    }, [crousal, getCrousalAction])

    // const SchoolImages = [
    //     { url: "assets/schoolImage1.jpg", alt: 'image' },
    //     { url: "assets/schoolImage2.jpg", alt: 'image' },
    //     { url: "assets/schoolImage3.jpg", alt: 'image' },
    //     { url: "assets/schoolImage4.jpg", alt: 'image' },
    //     { url: "assets/schoolImage5.jpg", alt: 'image' },
    // ]

    // const customArrowCss = {
    //     pos: 'absolute',
    //     cursor: 'pointer',
    //     top: '45%',
    //     color: 'white',
    //     borderRadius: '50%',
    //     bg: '#bd0745',
    //     w: '45px',
    //     h: '45px',
    //     mr: '40px',
    //     ml: '32px',
    //     zIndex: 98,
    //     display: { base: 'none', md: 'flex', lg: 'flex' }
    // }

    // const CustomRightArrow = ({ onClick }) => {
    //     return (
    //         <>
    //             <Center onClick={onClick} right='1%' {...customArrowCss}>
    //                 <MdKeyboardArrowRight fontSize='24px' />
    //             </Center>
    //         </>
    //     )
    // }
    // const CustomLeftArrow = ({ onClick }) => {
    //     return (
    //         <>
    //             <Center onClick={onClick} left='1%' {...customArrowCss}>
    //                 <MdKeyboardArrowLeft fontSize='24px' />
    //             </Center>
    //         </>
    //     )
    // }

    const width = useBreakpointValue({ sm: '395px', md: '720px', lg: '970px', xl: '1270px' });

    return (
        <>
            <MainLayout>
                <AspectRatio id='frontend' maxW='100%' ratio={21 / 9}>
                    <Image objectFit='cover' src={crousal?.image} crossOrigin='anonymous' />
                </AspectRatio>

                <Box display='flex' flexDirection='column' pos='relative' alignItems='center' overflow='hidden'>
                    {(facilities && facilities.length) ?
                        <Box top={'-70px'} w='100%'><Facilities /></Box>
                        :
                        null
                    }
                    <AboutUs width={width} />
                    <Desk width={width} />
                    {campusTour ?
                        <CampusTour width={width} campusTour={campusTour} />
                        :
                        null}
                    <Gallery width={width} />
                    <Achivement width={width} achivement={schoolData?.achievementDetails} />
                    <Toppers width={width} />
                    <ExperiencedStaff width={width} />
                </Box>
            </MainLayout>
        </>
    )
}