import { create } from "apisauce";
import { apiMonitor } from "./monitor";
// import NProgress from 'nprogress';


const BASE_URL = "https://websiteapi.jobdekhoapp.com/api/r1"


export const URIS = {
GET_SCHOOL_INFORMATION:'/school',
GET_SCHOOL_NEWS:'news/all',
GET_SCHOOL_EVENTS:'event/all',
GET_SCHOOL_TOPPERS:'topper/all',
ADD_FEEDBACK:'/feedback',
ADD_ENQUIRY:'/admission-enquiry',
GET_FACILITY: '/facility/all',
GET_CROUSAL: '/crousal/school',
GET_STAFF: '/staff/all',
GET_GALLERY: '/gallery/all',
}

let api = create({
    baseURL: BASE_URL,
    headers: {
        Accept: 'application/json',
        'Cache-Control': 'no-cache',
        'Content-Type': 'application/json',
    },
    timeout: 15000,
});
api.addMonitor(apiMonitor);

api.axiosInstance.interceptors.response.use(
    (response) => {
        return response;
    },
    async (error) => {
        let originalRequest = error.config;
        let isunauth = error.response && error.response.status === 401;
        if (
            isunauth &&
            !originalRequest._retry &&
            !originalRequest.headers._retry
        ) {
            originalRequest._retry = true;
        } else {
            return Promise.resolve(error);
        }
    },
);

export const setAuthorizationHeader = (access_token) => {
    if (!access_token) delete api.headers['Authorization'];
    else api.setHeader('Authorization', 'Bearer ' + access_token);
};

export const setSchoolHeader = (id) => {
    if (!id) delete api.headers['schoolid'];
    else api.setHeader('schoolid', id);
  };

export const removeAuthorizationHeader = () => {
    delete api.headers['Authorization'];
};

export const setUserAgent = (info) => api.setHeader("device", info);

export { api as apiClient };


// //for swr fetcher
// export const getFetcher = (url, params, config) =>
//   api
//     .get(url, params, config)
//     .then((response) => {
//       return response.data;
//     })
//     .catch((er) => {
//       throw er;
//     });

// // Add a request interceptor
// api.axiosInstance.interceptors.request.use(
//   function (config) {
//     // Do something before request is sent
//     // NProgress.configure({showSpinner:true})
//     NProgress.start();
//     return config;
//   },
//   function (error) {
//     // Do something with request error
//     NProgress.done();
//     console.error(error);
//     // eslint-disable-next-line no-undef
//     return Promise.reject(error);
//   },
// );

