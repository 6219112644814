import create from 'zustand';
import { STATUS } from '../../constants';
import { apis } from '../../service/apis/apis';

export const useStaffStore = create((set, get) => ({
    status: 'idle',
    token: null,
    resetStatus: async () => {
       
    },

    getStaffAction: async (payload) => {
        set({ getStaffStatus: STATUS.FETCHING })
        const response = await apis.getStaffApi(payload)
        if (response.ok) {
            set({ staffData: response.data, getStaffStatus: STATUS.SUCCESS })
        }
        else {
            set({ getStaffStatus: STATUS.FAILED })
        }
    }
}))