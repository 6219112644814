import { Box, Button, FormControl, FormLabel, Input, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay } from "@chakra-ui/react"
import { Controller, useForm } from "react-hook-form";
import { useEffect } from "react";
import { STATUS } from "../../constants";
import { useSchoolInformationStore } from "../../store/schoolInformation";
import { useAdmissionEnquiryStore } from "../../store/admissionEnquiry"

export const EnquiryForm = ({ isOpen, onClose }) => {
    const { control, handleSubmit, reset } = useForm();

    const { addAdmissionEnquiryAction, addAdmissionEnquiryStatus, resetStatus } = useAdmissionEnquiryStore(s => ({
        addAdmissionEnquiryAction: s.addAdmissionEnquiryAction,
        addAdmissionEnquiryStatus: s.addAdmissionEnquiryStatus,
        resetStatus: s.resetStatus,
    }))

    const { schoolData } = useSchoolInformationStore(s => ({ schoolData: s.schoolData }))

    const onSubmit = (data) => {
        const { name, email, fatherName, contact, session } = data;
        addAdmissionEnquiryAction({
            schoolId: schoolData?._id,
            studentName: name,
            email: email,
            fatherName: fatherName,
            contact: contact,
            class: data.class,
            session: session
        })
    }
console.log('addAdmissionEnquiryStatus',addAdmissionEnquiryStatus)
    useEffect(() => {
        if (addAdmissionEnquiryStatus === STATUS.SUCCESS) {
            reset()
            resetStatus()
            onClose()
        }
    }, [addAdmissionEnquiryStatus, onClose, reset, resetStatus])

    return (

        <Modal isOpen={isOpen} onClose={onClose} size='lg'>
            <ModalOverlay />
            <form onSubmit={handleSubmit(onSubmit)}>
                <ModalContent>
                    <ModalHeader>Enquiry Form</ModalHeader>
                    <Box px={0} h='1px' w='100%' bg='#eee' />
                    <ModalCloseButton />
                    <ModalBody>
                        <Box w='100%' display='flex' flexDir='column' alignItems='center'>
                            <FormControl isRequired mt={2}>
                                <FormLabel>Student Name</FormLabel>
                                <Controller
                                    name='name'
                                    control={control}
                                    render={({ field }) =>
                                        <Input {...field} size='sm' placeholder='Enter Your Name' boxShadow='inset 0 1px 1px rgba(0,0,0,.075)' />}
                                />
                            </FormControl>
                            <FormControl isRequired mt={4}>
                                <FormLabel>Father Name</FormLabel>
                                <Controller
                                    name='fatherName'
                                    control={control}
                                    render={({ field }) =>
                                        <Input {...field} size='sm' placeholder='Enter Your Father Name' boxShadow='inset 0 1px 1px rgba(0,0,0,.075)' />}
                                />
                            </FormControl>
                            <FormControl isRequired mt={4}>
                                <FormLabel>Contact No.</FormLabel>
                                <Controller
                                    name='contact'
                                    control={control}
                                    render={({ field }) =>
                                        <Input {...field} size='sm' placeholder='Enter Contact No.' type='tel' maxLength={10} pattern='[6-9]{1}[0-9]{9}' boxShadow='inset 0 1px 1px rgba(0,0,0,.075)' />}
                                />
                            </FormControl>
                            <FormControl my={4} isRequired>
                                <FormLabel>Class</FormLabel>
                                <Controller
                                    name='class'
                                    control={control}
                                    render={({ field }) =>
                                        <Input {...field} size='sm' placeholder='Enter Class' boxShadow='inset 0 1px 1px rgba(0,0,0,.075)' />}
                                />
                            </FormControl>
                        </Box>
                    </ModalBody>
                    <Box px={0} h='1px' w='100%' bg='#eee' />
                    <ModalFooter>
                        <Button
                            isLoading={addAdmissionEnquiryStatus === STATUS.FETCHING}
                            size='sm'
                            bg='#bd0745'
                            _hover={{ bg: '#9c0639' }}
                            color='white'
                            type='submit'
                        >
                            Submit
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </form>
        </Modal>

    )
}