import { AspectRatio, Box, Button, Center, Image, Link, Text } from "@chakra-ui/react"
import Carousel from "react-multi-carousel";
import { map } from "lodash";
import { useSchoolTopperStore } from "../../store/topper";
import { useEffect } from "react";
import { ArrowForwardIcon } from "@chakra-ui/icons";
import { SchoolId } from "../../config";

export const Toppers = ({ width }) => {
    const { getSchoolToppersAction, schoolToppersData } = useSchoolTopperStore(s => ({
        getSchoolToppersAction: s.getSchoolToppersAction,
        schoolToppersData: s.schoolToppersData
    }))

    useEffect(() => {
        if (!schoolToppersData) {
            getSchoolToppersAction({ schoolId: SchoolId })
        }
    }, [getSchoolToppersAction, schoolToppersData])

    const responsive = {
        superLargeDesktop: {
            breakpoint: { max: 4000, min: 3000 },
            items: 4,
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 4
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 3,
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1,
        }
    };

    return (
        <Box w='full'>
            {schoolToppersData && schoolToppersData.length ?
                <Box py='40px' px='15px' w={width} mx='auto' textAlign='center'>
                    <Text fontWeight={500} fontSize='30px' mt='20px' mb='10px'>Toppers</Text>
                    <Carousel
                        swipeable={false}
                        draggable={false}
                        showDots={false}
                        responsive={responsive}
                        centerMode={false}
                        ssr={true}
                        infinite={true}
                        autoPlay={true}
                        arrows={false}
                    >
                        {map(schoolToppersData, t =>
                            <TopperDetail key={t._id} detail={t} />
                        )}
                    </Carousel>
                    <Link href='/toppers' _hover={{ textDecor: 'none' }}>
                        <Center>
                            <Button size='sm' role='group' borderRadius='17px' bg='#bd0745' color='white' _hover={{ bg: '#9c0639' }}>
                                View More
                                <ArrowForwardIcon
                                    fontSize='18px'
                                    opacity={0}
                                    transition="all 0.2s"
                                    ml="-10px"
                                    _groupHover={{ opacity: 1, marginLeft: "5px" }}
                                />
                            </Button>
                        </Center>
                    </Link>
                </Box>
                :
                null
            }
        </Box>
    )
}

export const TopperDetail = ({ key, detail }) => {
    return (
        <Box p='15px' key={key} w='300px' display='flex' flexDir='column' alignItems='center' textAlign='center'>
            <Box w='180px'>
                <Text fontSize='24px'>{`Class ${detail.class}`}</Text>
                <AspectRatio ratio={1} maxW='180px'>
                    <Image src={detail.avatar || 'assets/blank_profile.webp'} alt='toppers' borderRadius='4px' objectFit='cover' crossOrigin='anonymous' />
                </AspectRatio>
                <Text fontWeight={400} fontSize='16px'>{detail.studentName}</Text>
                <Text fontWeight={400} fontSize='14px'>{`${detail.marks}${(detail.marksType === 'grade') ? 'CGPA' : '%'} (${detail.session})`}</Text>
            </Box>
        </Box>
    )
}