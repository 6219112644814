import { Box, Text, useBreakpointValue } from "@chakra-ui/react"
import MainLayout from "../../app/layouts/MainLayout"
import { useSchoolFacilityStore } from "../../store/facility";
import { find } from "lodash";
import { useMemo } from "react";

export const Clubs = () => {
    const width = useBreakpointValue({ sm: '395px', md: '720px', lg: '970px', xl: '1270px' });
    const { facilities } = useSchoolFacilityStore(s => ({ facilities: s.facilities }))
    const club = useMemo(() => find(facilities, f => f.title === 'CLUB'), [facilities])
    return (
        <MainLayout>
            <Box py='40px' w='full'>
                <Box px='15px' w={width} mx='auto'>
                    <Box>
                        <Text fontWeight={500} fontSize='30px' mt='20px' mb='10px'>Clubs</Text>
                        <Text fontSize='16px' mb='10px'>{club?.description}</Text>
                    </Box>
                </Box>
            </Box>
        </MainLayout>
    )
}