import { Box, Button, Center, useDisclosure, Flex } from "@chakra-ui/react"
import { useEffect, useState } from "react";
import { IoIosArrowUp } from "react-icons/io"
import { Footer } from "./Footer";
import { Header } from "./Header";
import { EnquiryForm } from "../../components/enquiry";
import { useSchoolInformationStore } from "../../store/schoolInformation";
import { SchoolId } from "../../config";
import { setSchoolHeader } from "../../service/apis";

export default function MainLayout(props) {
    const [isVisible, setIsVisible] = useState(false);
    const { onOpen, onClose, isOpen } = useDisclosure()

    const { getSchoolInformationAction, schoolData } = useSchoolInformationStore(s => ({
        getSchoolInformationAction:s.getSchoolInformationAction,
        schoolData:s.schoolData
    }))

    useEffect(() => {
        if(!schoolData){
            setSchoolHeader(SchoolId)
            getSchoolInformationAction({id:SchoolId})
        }
    }, [ getSchoolInformationAction, schoolData ])

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }

    useEffect(() => {
        const handleScroll = () => {
            const currentPosition = window.pageYOffset;
            if (currentPosition > 300) {
                setIsVisible(true);
            } else {
                setIsVisible(false);
            }
        };
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener("scroll", handleScroll);
    }, []);



    return (
        <Box minH='100vh'>
            <Header />
            {props.children}
            <Footer />
            {isVisible && <Center onClick={scrollToTop} scrollBehavior='smooth' w='40px' h='40px' borderRadius='50%' bg='#bd0745' _hover={{ bg: 'black' }} zIndex={99} color='white' pos='fixed' boxShadow='0 2px 3px rgba(0, 0, 0, 0.2)' transition='all .5s' bottom='20px' right='30px'><IoIosArrowUp fontSize='30px' /></Center>}
            <Flex>
                <Button bg='#bd0745' _hover={{ bg: '#9c0639' }} color='white' onClick={onOpen} pos='fixed' boxShadow='0 2px 3px rgba(0, 0, 0, 0.2)' zIndex={99} borderTopRadius={0} borderBottomRadius={4} transform='rotate(90deg)' top='50%' right='-48px'>Enquiry Form</Button>
            </Flex>
            {isOpen && <EnquiryForm isOpen={isOpen} onClose={onClose} />}
        </Box>
    )
}