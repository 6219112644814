import { Box, Button, FormControl, FormLabel, Input, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay } from "@chakra-ui/react"

export const Login = ({ isOpen, onClose }) => {

    const FooterButtonCss = {
        color: 'white',
        boxShadow: '0px 5px 15px 0px rgb(25 25 25 / 41%)',
        borderRadius: '30px',
        size: 'sm',
        mr: '3px',
        px: '25px'
    }

    const InputCss = {
        size: 'sm',
        boxShadow: 'inset 0 1px 1px rgba(0,0,0,.075)',
        borderRadius: '4px',
        border: '1px solid #ccc'
    }
    return (
        <Modal isOpen={isOpen} onClose={onClose} size='xl'>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>
                    <Box fontSize='18px' fontWeight={500}>Login</Box>
                </ModalHeader>
                <Box px={0} h='1px' w='100%' bg='#eee' />
                <ModalCloseButton />
                <ModalBody>
                    <Box>
                        <FormControl isRequired mb='10px'>
                            <FormLabel>Email ID</FormLabel>
                            <Input {...InputCss} />
                        </FormControl>
                        <FormControl isRequired mb='10px'>
                            <FormLabel>Password</FormLabel>
                            <Input {...InputCss} />
                        </FormControl>
                    </Box>
                </ModalBody>
                <Box px={0} h='1px' w='100%' bg='#eee' />
                <ModalFooter>
                    <Button
                        {...FooterButtonCss}
                        bg='#786e6d'
                        _hover={{ bg: '#786e6d' }}
                    >
                        Sign Up
                    </Button>
                    <Button
                        {...FooterButtonCss}
                        bg='#bd0745'
                        _hover={{ bg: '#bd0745' }}
                    >
                        Submit
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    )
}