import create from 'zustand';
import { STATUS } from '../../constants';
import { apis } from '../../service/apis/apis';

export const useSchoolNewsStore = create((set, get) => ({
    status: 'idle',
    token: null,
    resetStatus: async () => {
       
    },

    getSchoolNewsAction: async (payload) => {
        set({ getSchoolNewsStatus: STATUS.FETCHING })
        const response = await apis.getSchoolNewsApi(payload)
        if (response.ok) {
            set({ schoolNewsData: response.data, getSchoolNewsStatus: STATUS.SUCCESS })
        }
        else {
            set({ getSchoolNewsStatus: STATUS.FAILED })
        }
    },
    getSchoolEventsAction: async (payload) => {
        set({ getSchoolEventsStatus: STATUS.FETCHING })
        const response = await apis.getSchoolEventsApi(payload)
        if (response.ok) {
            set({ schoolEventsData: response.data, getSchoolEventsStatus: STATUS.SUCCESS })
        }
        else {
            set({ getSchoolEventsStatus: STATUS.FAILED })
        }
    }

}))