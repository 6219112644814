import { Box, Center, Flex, Image, Text, useBreakpointValue } from "@chakra-ui/react"

import { map, orderBy } from "lodash"
import MainLayout from "../../app/layouts/MainLayout"
import { useSchoolNewsStore } from "../../store/news"
import dayjs from "dayjs"
import { EmptyBox } from "../../common/EmptyBox"

export const News = () => {
    const width = useBreakpointValue({ base: '325px', md: '720px', lg: '970px', xl: '1270px' });
    const { schoolNewsData } = useSchoolNewsStore(s => ({
        schoolNewsData: s.schoolNewsData,
    }))

    return (
        <MainLayout>
            <Box display='flex' flexDir='column' alignItems='center'>
                <Box py='60px' w={width} id='about-us'>
                    <Box px='15px' mx='auto'>
                        <Text fontWeight={500} fontSize='30px' mt='20px' mb='10px'>News</Text>
                    </Box>
                    {schoolNewsData && schoolNewsData.length ?
                        orderBy(schoolNewsData, 'updatedAt', 'desc').map(news =>
                            <Box key={news._id} display='flex' mt='20px' p='10px' flexDir={{ base: 'column', md: 'row', lg: 'row' }} boxShadow='0px 0px 10px rgba(0, 0, 0, 0.15)' borderRadius='4px'>
                                <Box pos='relative' flex={1 / 4}>
                                    <Image src={news.image || 'assets/noImage.png'} alt="News" borderRadius='5px' objectFit='cover' crossOrigin='anonymous' w='100%' h='100%' />
                                    <Box _hover={{ bg: 'rgba(33, 33, 33, 0.5)' }} transition='all 0.3s ease 0s' pos='absolute' borderRadius='5px' w='100%' h='100%' left={0} bottom={0} />
                                </Box>
                                <Box flex={1} px='15px' mt={{ base: '30px', md: '0px', lg: '0px' }}>
                                    <Flex justify='space-between' flexDir={{ base: 'column-reverse', md: 'row', lg: 'row' }}>
                                        <Text fontSize='18px' fontWeight={500} color='#bd0745'>{news.title}</Text>
                                        <Text fontStyle='italic' fontSize={14} color='gray.500'><span style={{ color: 'red.200' }}>Updated on:&nbsp;</span>{dayjs(news.updatedAt).format('DD MMM YYYY')}</Text>
                                    </Flex>
                                    <Text pb='10px' fontSize='14px'>{news.description}</Text>
                                </Box>
                            </Box>
                        )
                        :
                        <EmptyBox/>
                    }
                </Box>
            </Box>
        </MainLayout>
    )
}