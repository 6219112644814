import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, AspectRatio, Box, Button, Center, Flex, HStack, Image, Link, Text } from "@chakra-ui/react"
import { find, map } from "lodash"
import { FACILITIES } from "../../constants"
import { useSchoolInformationStore } from "../../store/schoolInformation"
import { useSchoolFacilityStore } from "../../store/facility"
import { BsArrowRightShort } from "react-icons/bs"

export const AboutUs = ({ width }) => {

    const { schoolData } = useSchoolInformationStore(s => ({ schoolData: s.schoolData }))

    const { about, aboutImage } = schoolData || {}
    console.log('school', aboutImage)
    return (
        <>
            <Box py='40px' w={width} id='about-us'>
                <Box px='15px' mx='auto' textAlign='center' display='flex' flexDir='column' alignItems='center'>
                    <Text fontWeight={500} fontSize='30px' mt='20px' mb='10px'>ABOUT US</Text>
                    <Text
                        maxW='70%'
                        noOfLines={2}
                        overflow='hidden'
                        textOverflow='ellipsis'
                        fontSize='14px'
                        mb='10px'>
                        {about}
                    </Text>
                    {about &&
                        <Link href='/about-us'>
                            <Button color='#bd0745' variant='outline' size='xs'>View More</Button>
                        </Link>
                    }
                    <Box h='20px'></Box>
                </Box>
                <Flex mt='30px' flexDir={{ base: 'column', md: 'row', lg: 'row' }}>
                    <AspectRatio flex={1} px='15px' ratio={3 / 2}>
                        <Box pos='relative'>
                            <Image src={aboutImage || 'assets/noImage.png'} alt="about" borderRadius='5px' objectFit='cover' w='100%' h='100%' crossOrigin='anonymous' />
                            <Box _hover={{ bg: 'rgba(33, 33, 33, 0.5)' }} transition='all 0.3s ease 0s' pos='absolute' borderRadius='5px' w='100%' h='100%' left={0} bottom={0}></Box>
                        </Box>
                    </AspectRatio>
                    <Box flex={1} px='15px' mt={{ base: '30px', md: '0px', lg: '0px' }}>
                        <Text fontSize='24px' fontWeight={500} mb='10px'>{`WELCOME TO ${schoolData?.name}`}</Text>
                        <Box borderRadius='4px' border='1px solid #ddd' boxShadow='0 1px 1px rgba(0,0,0,.05)'>
                            <Accordion defaultIndex={[0]} borderRadius='5px'>
                                <AccordionItem>
                                    <h2>
                                        <AccordionButton bg='#bd0745' _hover={{ bg: '#bd0745' }}>
                                            <Box as="span" flex='1' textAlign='left' transition='all 1s ease 0s' _hover={{ color: '#72afd2' }} fontSize='16px' fontWeight={500} color='white'>
                                                Vision
                                            </Box>
                                            <AccordionIcon color='white' />
                                        </AccordionButton>
                                    </h2>
                                    <AccordionPanel p='15px'>
                                        <Box>
                                            {schoolData?.vision}
                                            <Link _hover={{ textDecor: 'none' }} href='/vision-and-mission'>
                                                &nbsp;<Button color='#bd0745' variant='flushed' size='xs' _hover={{ color: '#9c0639', textDecor: 'underline' }}>View More</Button>
                                            </Link>
                                        </Box>
                                    </AccordionPanel>
                                </AccordionItem>
                                <AccordionItem>
                                    <h2>
                                        <AccordionButton bg='#bd0745' _hover={{ bg: '#bd0745' }}>
                                            <Box as="span" flex='1' textAlign='left' transition='all 1s ease 0s' _hover={{ color: '#72afd2' }} fontSize='16px' fontWeight={500} color='white'>
                                                Mission
                                            </Box>
                                            <AccordionIcon color='white' />
                                        </AccordionButton>
                                    </h2>
                                    <AccordionPanel p='15px'>
                                        <Box>
                                            {schoolData?.mission}
                                            <Link _hover={{ textDecor: 'none' }} href='/vision-and-mission'>
                                                &nbsp;<Button color='#bd0745' variant='flushed' size='xs' _hover={{ color: '#9c0639', textDecor: 'underline' }}>View More</Button>
                                            </Link>
                                        </Box>
                                    </AccordionPanel>
                                </AccordionItem>
                            </Accordion>
                        </Box>
                    </Box>
                </Flex>
            </Box>
        </>
    )
}

export const Facilities = () => {
    const { facilities } = useSchoolFacilityStore(s => ({ facilities: s.facilities }))
    return (
        <Flex flexDir={{ base: 'column', md: 'row', lg: 'row' }}>
            {map(FACILITIES, f => {
                const findFacility = find(facilities, fac => fac.title === f.id)
                return (
                    findFacility ?
                        <Box key={f.title} transition='all 0.5s ease-out' borderRight='1px solid rgba(255, 255, 255, 0.21)' pt='30px' pr='30px' pb='20px' pl='40px' color='white' bg='#bd0745' flex={1}>
                            <Flex align='start' justify='space-between'>
                                <HStack>
                                    <Image src={f.image} alt='image' w='50px' h='50px' color='white' />
                                    <Box pl='10px'>
                                        <Text fontSize='24px' fontWeight={500}>{findFacility?.title}</Text>
                                        <Text fontSize='14px' fontWeight={400} noOfLines={2} w='80%'>{findFacility?.description}</Text>
                                    </Box>
                                </HStack>
                                <Link href={f.route}><Center borderRadius={50} w={8} h={8} bg='white'><BsArrowRightShort color='#bd0745' fontSize='20px' /></Center></Link>
                            </Flex>
                        </Box>
                        :
                        null
                )
            }
            )}
        </Flex>
    )
}