import { AspectRatio, Box, Center, Flex, Image, Text } from "@chakra-ui/react"
import { map } from "lodash"

export const Achivement = ({ width, achivement }) => {
    console.log('achivement', achivement)

    const handleIcon = (key) => {
        switch (key) {
            case 'alumini':
                return 'assets/scholarship-icon.png';
            case 'studentCampuses':
                return 'assets/campus.jpg';
            case 'teachers':
                return 'assets/teacher.png';
            case 'students':
                return 'assets/student.png'
            default:
                return;
        }
    }

    const handleTitle = (key) => {
        switch (key) {
            case 'alumini':
                return 'ALUMINI';
            case 'studentCampuses':
                return 'STUDENT CAMPUSES';
            case 'teachers':
                return 'CERTIFIED TEACHERS';
            case 'students':
                return 'STUDENTS';
            default:
                return;
        }
    }

    if (!achivement) return;
    const { image, des, achivements } = achivement || {}
    return (
        <Box bg='rgb(32, 44, 69)' w='full' pt='70px' py='60px' display='flex' flexDir='column' alignItems='center' >
            <Flex w={width} flexDir={{ base: 'column', md: 'row', lg: 'row' }}>
                <Box flex={1} px='15px'>
                    <Text fontSize='30px' fontWeight={500} mb='10px' color='white'>ACHIVEMENTS</Text>
                    <Text fontSize='14px' mb='10px' color='white'>{des}</Text>
                    <AspectRatio ratio={3/2}>
                        <Box pos='relative' mb={{ base: '14px' }}>
                            <Image src={image || 'assets/noImage.png'} alt='image' objectFit='cover' borderRadius='6px' crossOrigin='anonymous' w='100%' h='100%' />
                            <Box w='100%' h='100%' left={0} bottom={0} _hover={{ bg: 'rgba(33, 33, 33, 0.5)' }} transition='all 0.3s' borderRadius='6px' pos='absolute' opacity={1}></Box>
                        </Box>
                    </AspectRatio>
                </Box>
                <Flex flex={1} flexWrap='wrap' flexDir={{ base: 'column', md: 'row', lg: 'row' }}>
                    {map(achivements, (a, index) =>
                        <Box key={index} w={{ base: '100%', md: '50%', lg: '50%' }} px='15px' mb='15px'>
                            <Box
                                _hover={{ boxShadow: '0 6px 29px rgba(0,0,0,0.3)', transform: 'scale(1.1)' }}
                                border='1px solid rgba(255, 255, 255, 0.19)'
                                borderRadius='4px'
                                p='30px 30px 18px 30px'
                                transition='all 0.3s ease-in-out'
                            >
                                <Box textAlign='center' w={{ base: 'full', md: '95px', lg: '150px', xl: '225px' }} h='185px'>
                                    <Center>
                                        <Image w='50px' h='50px' src={handleIcon(a.title)} crossOrigin='anonymous' />
                                    </Center>
                                    <Text fontSize='48px' color='white' fontWeight={700}>{a.value}</Text>
                                    <Box bg='#fdd333' h='1px' mb='15px'> </Box>
                                    <Text fontSize='15px' color='white' fontWeight={700}>{handleTitle(a.title)}</Text>
                                </Box>
                            </Box>
                        </Box>
                    )}
                </Flex>
            </Flex>
        </Box>
    )
}