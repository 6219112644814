import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, AspectRatio, Box, Center, Flex, Image, Text, useBreakpointValue } from "@chakra-ui/react"
import MainLayout from "../../app/layouts/MainLayout"
import { map } from "lodash";
import { useSchoolTopperStore } from "../../store/topper";
import { useEffect } from "react";
import { SchoolId } from "../../config";
import { TopperDetail } from "../homePage/Topper";
import { EmptyBox } from "../../common/EmptyBox";

export const Toppers = () => {
    const width = useBreakpointValue({ sm: '395px', md: '720px', lg: '970px', xl: '1270px' });

    const { getSchoolToppersAction, schoolToppersData } = useSchoolTopperStore(s => ({
        getSchoolToppersAction: s.getSchoolToppersAction,
        schoolToppersData: s.schoolToppersData
    }))

    useEffect(() => {
        if (!schoolToppersData) {
            getSchoolToppersAction({ schoolId: SchoolId })
        }
    }, [getSchoolToppersAction, schoolToppersData])

    return (
        <MainLayout>
            <Box py='40px' w='full'>
                <Box px='15px' w={width} mx='auto'>
                    <Box>
                        <Text fontWeight={500} fontSize='30px' mt='20px' mb='10px'>Our Toppers</Text>
                    </Box>
                    <Box borderRadius='4px' border='1px solid #ddd' boxShadow='0 1px 1px rgba(0,0,0,.05)'>
                        <Accordion defaultIndex={[0]} borderRadius='5px'>
                            <AccordionItem>
                                <h2>
                                    <AccordionButton bg='#bd0745' _hover={{ bg: '#bd0745' }}>
                                        <Box as="span" flex='1' textAlign='left' transition='all 1s ease 0s' _hover={{ color: '#72afd2' }} fontSize='16px' fontWeight={500} color='white'>
                                            Topper List
                                        </Box>
                                        <AccordionIcon color='white' />
                                    </AccordionButton>
                                </h2>
                                <AccordionPanel pb={4}>
                                    <Flex wrap='wrap'>
                                        {schoolToppersData && schoolToppersData.length ?
                                            map(schoolToppersData, t =>
                                                <TopperDetail key={t._id} detail={t} />
                                            )
                                            :
                                            <EmptyBox />
                                        }
                                    </Flex>
                                </AccordionPanel>
                            </AccordionItem>
                        </Accordion>
                    </Box>
                </Box>
            </Box>
        </MainLayout>
    )
}