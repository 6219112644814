import create from 'zustand';
import { STATUS } from '../../constants';
import { apis } from '../../service/apis/apis';

export const useCrousalStore = create((set, get) => ({
    status: 'idle',
    token: null,
    resetStatus: async () => {
        set({
        })
    },

    getCrousalAction: async (payload) => {
        set({ getCrousalStatus: STATUS.FETCHING })
        const response = await apis.getCrousalApi(payload)
        if (response.ok) {
            set({ crousal: response.data, getCrousalStatus: STATUS.SUCCESS })
        }
        else {
            set({ getCrousalStatus: STATUS.FAILED })
        }
    }

}))