import { Box, Button, Flex, FormControl, FormLabel, Input, Textarea, useBreakpointValue } from "@chakra-ui/react"
import MainLayout from "../../app/layouts/MainLayout"
import { useEffect } from "react";
import { STATUS } from "../../constants";
import { useFeedbackStore } from "../../store/feedback";
import { Controller, useForm } from "react-hook-form";
import { useSchoolInformationStore } from "../../store/schoolInformation";

export const ComplainForm = () => {
    const width = useBreakpointValue({ sm: '395px', md: '630px', lg: '810px', xl: '1000px' });

    const { control, handleSubmit, setValue } = useForm();

    const { addFeedbackAction, addFeedbackStatus, resetStatus } = useFeedbackStore(s => ({
        addFeedbackAction: s.addFeedbackAction,
        addFeedbackStatus: s.addFeedbackStatus,
        resetStatus: s.resetStatus,
    }))

    const { schoolData } = useSchoolInformationStore(s => ({ schoolData: s.schoolData }))

    const onSubmit = (data) => {
        const { name, email, description, contact } = data;
        const feedback = {
            schoolId: schoolData?._id,
            feedbackType: "COMPLAINT",
            name: name,
            email: email,
            contact: contact,
            description: description
        }
        addFeedbackAction(feedback)
    }
    console.log('compalaint', addFeedbackStatus);

    useEffect(() => {
        if (addFeedbackStatus === STATUS.SUCCESS) {
            setValue('name', '')
            setValue('email', '')
            setValue('description', '')
            setValue('contact', '')
            resetStatus()
        }
    }, [addFeedbackStatus, setValue, resetStatus])

    return (
        <MainLayout>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Box w='100%' display='flex' flexDir='column' alignItems='center' my='40px'>
                    <Box w={width} p={2}>
                        <FormControl isRequired mt={4}>
                            <Flex justify={{ md: 'center', lg: 'center' }} flexDir={{ base: 'column', md: 'row', lg: 'row' }}>
                                <Flex justify={{ md: 'end', lg: 'end' }} w='15%'><FormLabel>Name</FormLabel></Flex>
                                <Controller
                                    name='name'
                                    control={control}
                                    render={({ field }) =>
                                        <Input {...field} size='sm' placeholder='Enter Your Name' boxShadow='inset 0 1px 1px rgba(0,0,0,.075)' />}
                                />
                            </Flex>
                        </FormControl>
                        <FormControl isRequired mt={4}>
                            <Flex justify={{ md: 'center', lg: 'center' }} flexDir={{ base: 'column', md: 'row', lg: 'row' }}>
                                <Flex justify={{ md: 'end', lg: 'end' }} w='15%'><FormLabel>Email</FormLabel></Flex>
                                <Controller
                                    name='email'
                                    control={control}
                                    render={({ field }) =>
                                        <Input {...field} size='sm' placeholder='Enter Your Email' type='email' boxShadow='inset 0 1px 1px rgba(0,0,0,.075)' />}
                                />
                            </Flex>
                        </FormControl>
                        <FormControl isRequired mt={4}>
                            <Flex justify={{ md: 'center', lg: 'center' }} flexDir={{ base: 'column', md: 'row', lg: 'row' }}>
                                <Flex justify={{ md: 'end', lg: 'end' }} w='15%'><FormLabel>Contact No.</FormLabel></Flex>
                                <Controller
                                    name='contact'
                                    control={control}
                                    render={({ field }) =>
                                        <Input {...field} size='sm' placeholder='Enter Contact No.' type='tel' maxLength={10} pattern='[6-9]{1}[0-9]{9}' boxShadow='inset 0 1px 1px rgba(0,0,0,.075)' />}
                                />
                            </Flex>
                        </FormControl>
                        <FormControl mt={4}>
                            <Flex justify={{ md: 'center', lg: 'center' }} flexDir={{ base: 'column', md: 'row', lg: 'row' }}>
                                <Flex justify={{ md: 'end', lg: 'end' }} w='15%'><FormLabel>Description</FormLabel></Flex>
                                <Controller
                                    name='description'
                                    control={control}
                                    render={({ field }) =>
                                        <Textarea {...field} size='sm' placeholder='Enter Description' boxShadow='inset 0 1px 1px rgba(0,0,0,.075)' />}
                                />
                            </Flex>
                        </FormControl>
                        <Flex justify='end' >
                            <Button
                                isLoading={addFeedbackStatus === STATUS.FETCHING}
                                size='sm'
                                bg='#337ab7'
                                my='14px'
                                color='white'
                                type='submit'
                            >
                                Submit
                            </Button>
                        </Flex>
                    </Box>
                </Box>
            </form>
        </MainLayout>
    )
}