import { URIS, apiClient } from ".";


export const apis = {
      getSchoolInformationApi: (payload) => apiClient.get(URIS.GET_SCHOOL_INFORMATION, payload),
      getSchoolNewsApi: (payload) => apiClient.get(URIS.GET_SCHOOL_NEWS, payload),
      getSchoolEventsApi: (payload) => apiClient.get(URIS.GET_SCHOOL_EVENTS, payload),
      getSchoolToppersApi: (payload) => apiClient.get(URIS.GET_SCHOOL_TOPPERS, payload),
      addFeedbackApi: (payload) => apiClient.post(URIS.ADD_FEEDBACK, payload),
      addAdmissionEnquiryApi: (payload) => apiClient.post(URIS.ADD_ENQUIRY, payload),
      getFacilityApi: (payload) => apiClient.get(URIS.GET_FACILITY, payload),
      getCrousalApi: (payload) => apiClient.get(URIS.GET_CROUSAL, payload),
      getStaffApi: (payload) => apiClient.get(URIS.GET_STAFF, payload),
      getGalleryApi: (payload) => apiClient.get(URIS.GET_GALLERY, payload),
};
