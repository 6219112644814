import { useState } from "react";
import { Box, AspectRatio, Text, useBreakpointValue, Flex, Image } from '@chakra-ui/react'

import { find, map } from "lodash";
import MainLayout from "../../app/layouts/MainLayout"

import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import Fullscreen from "yet-another-react-lightbox/plugins/fullscreen";
import Slideshow from "yet-another-react-lightbox/plugins/slideshow";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import "yet-another-react-lightbox/plugins/thumbnails.css";
import { useLocation, useParams } from "react-router";
import { useSchoolGalleryStore } from "../../store/gallery";
import { EmptyBox } from "../../common/EmptyBox";

export const GalleryDetail = () => {
    const width = useBreakpointValue({ sm: '395px', md: '720px', lg: '970px', xl: '1270px' });
    const [index, setIndex] = useState(-1)
   
const [currentIndex, setCurrentIndex] = useState(0);

    const { gallery } = useSchoolGalleryStore(s => ({
        gallery: s.gallery
    }))

    const location = useLocation()
    const searchParams = new URLSearchParams(location.search);
    const albumId = searchParams.get('id');

    const findAlbum = find(gallery, album => album._id === albumId)
    const albumImages = findAlbum && findAlbum.images

    const slides = map(albumImages, (src) => ({ src: src }));
    console.log('slides', slides)

    const CustomImageRenderer = ({ photo }) => {
        console.log('photo', photo)
        return (
            <img
                src={photo.src}
                alt={photo.alt}
                crossOrigin="anonymous"
            />
        );
    };

    const goToPrevious = () => {
        setCurrentIndex((prevIndex) => (prevIndex === 0 ? albumImages.length - 1 : prevIndex - 1));
      };

      const goToNext = () => {
        setCurrentIndex((prevIndex) => (prevIndex === albumImages.length - 1 ? 0 : prevIndex + 1));
      };
    return (
        <MainLayout>
            <Box py='40px' w='full'>
                <Box px='15px' w={width} mx='auto'>
                    {albumImages && albumImages.length ?
                        <Box>
                            <Text fontWeight={500} fontSize='24px' mt='20px' mb='10px'>{findAlbum.title}</Text>
                            <Flex wrap='wrap'>
                                {map(albumImages, (src, index) =>
                                    <Box w='220px' m={2} onClick={() => setIndex(index)}>
                                        <AspectRatio ratio={1} key={index}>
                                            <Image borderRadius='4px 4px 4px 4px' src={src} alt='image' objectFit='cover' crossOrigin='anonymous' />
                                        </AspectRatio>
                                    </Box>
                                )}
                            </Flex>
                            <Lightbox
                                slides={slides}
                                open={index >= 0}
                                index={index}
                                close={() => setIndex(-1)}
                                plugins={[Fullscreen, Slideshow, Thumbnails, Zoom]}
                                renderImage={(props) => <CustomImageRenderer {...props} />}
                            />

                        </Box>
                        :
                        <EmptyBox />
                    }
                </Box>
            </Box>
        </MainLayout>
    )
}