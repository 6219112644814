import { Box, AspectRatio, Link, Image, Center, Text, Flex, useBreakpointValue } from '@chakra-ui/react'
import MainLayout from '../../app/layouts/MainLayout'
import { map } from 'lodash'
import { useSchoolGalleryStore } from '../../store/gallery';
import { Album } from '../homePage/Gallery';

export const Gallery = () => {
    const width = useBreakpointValue({ sm: '395px', md: '720px', lg: '970px', xl: '1270px' });
    const { gallery } = useSchoolGalleryStore(s => ({
        gallery: s.gallery
    }))
  
    return (
        <MainLayout>
            <Box py='40px' w='full'>
                <Box px='15px' w={width} mx='auto'>
                    <Box>
                        <Text fontWeight={500} fontSize='30px' mt='20px' mb='10px'>Gallery</Text>
                    </Box>
                    <Flex wrap='wrap'>
                        {map(gallery, (album, index) =>
                            
                            <Album album={album} key={album._id} width='300px'/>
                        )}
                    </Flex>
                </Box>
            </Box>
        </MainLayout>
    )
}